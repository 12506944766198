import React, { useState, useContext,useCallback, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { DEFAULT_LAT, DEFAULT_LON, KEY_MAPS } from "helpers/service";
import AccountContext from "context/Account";
const GoogleMaps = ({ 
  stores = [], 
  route = [], 
  order = [],
  search = "", 
  onMapClick = () => {}, }) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const {setAddressMap, addProvince, setAddProvince} = useContext(AccountContext);
  const { isLoaded } = useJsApiLoader({
    
    id: "google-map-script",
    googleMapsApiKey: KEY_MAPS,
  });
  const mapStyle = {
    width: "100%",
    height: "100%",
  };

  //implementar el default position de esta manera no funciona
  const defaultPosition = {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LON,
  };

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(defaultPosition);
    map?.fitBounds(bounds);
    setMap(map);

  }, []);

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDwAG-AYiPvLg_BKrmdMA2sRquk3U_6Fvc`
      );
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const formattedAddress = data.results[0].formatted_address;

        if(addProvince === ""){

          setAddressMap(formattedAddress); // Actualiza el estado con la dirección
        }

        return formattedAddress;
      } else {
        setAddressMap("No se pudo obtener la dirección.");
        return "No direccion";
      }
    } catch (error) {
      console.error("Error al obtener la dirección:", error);
      setAddressMap("Error al obtener la dirección.");
    }
  };


  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarker({ lat, lng });
    onMapClick({ lat, lng });
    await fetchAddress(lat, lng);
  };


  useEffect(() => {
   
    const fetchCoordinates = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
           search === ''?addProvince:search
          )}&key=AIzaSyDwAG-AYiPvLg_BKrmdMA2sRquk3U_6Fvc`
        );
        const data = await response.json();
         if(search !== "") setAddProvince("")
        if (data.status === "OK" && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          setMarker({ lat, lng });
          await fetchAddress(lat, lng);
        } else {
          return
        }
        setAddProvince("")
      } catch (error) {
        console.error("Error al buscar la dirección:", error);
      }
    };

    fetchCoordinates();
  }, [search, addProvince, map]);

  



  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
let lat =  marker?parseFloat(marker?.lat):18.4660228;
let long = marker?parseFloat(marker?.lng):-69.9131827;
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapStyle}
      center={{
              lat: lat,
              lng: long,
            }}
      zoom={15}
      onLoad={() => onLoad(map)}
      onUnmount={onUnmount}
      onClick={handleMapClick}
    >
      {
        order?.map((order, index) => (
          <Marker
            icon="/assets/global/pin_home.png"
            key={index}
            animation={1}
            position={{
              lat: parseFloat(order.shipping_lat),
              lng: parseFloat(order.shipping_lon),
            }}
          />
        ))}

      {
        route?.map((route, idx) => (
          <Marker
            icon="/assets/global/pin_driver.png"
            key={idx}
            animation={1}
            position={
              route && {
                lat: parseFloat(route?.driver_lat),
                lng: parseFloat(route?.driver_lon),
              }
            }
          />
        ))}

   
      {marker == null &&(
          <Marker
          icon="/assets/global/gmarker.png"
          animation={1}
          position={
           {
              lat: parseFloat(lat),
              lng: parseFloat(long),
            }
          }
        />
    
      )}

      {stores?.map((item, idx) => (
        <Marker
          icon="/assets/global/gmarker.png"
          key={idx}
          animation={1}
          position={{
            lat: parseFloat(item?.lat),
            lng: parseFloat(item?.lon),
          }}
        />
      ))}
        {marker != null && (
           <Marker
              icon="/assets/global/pin_home.png"
              animation={1}
              position={marker}
            />
           
          )}
    </GoogleMap>
  ) : (
    <>Cargando...</>
  );
};
export default React.memo(GoogleMaps);
