import ReactDOM from "react-dom";
import React, { useEffect, useContext,useState } from "react";
import { useForm } from "react-hook-form";
import { postData } from "helpers/service";
import { Encode } from "helpers/functions";
import InputMask from 'react-input-mask';
import GoogleMaps from 'components/share/maps'
import "react-credit-cards-2/dist/es/styles-compiled.css";
import AccountContext from "context/Account";


export default function CreditCardAdd({ isOpen, setClose }) {
  const [focus, setFocus] = useState("number");
  const [loading, setLoading] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const {addressMap} = useContext(AccountContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({mode:'onChange'});

// console.log(watch.search);
 

  useEffect(() => {
    if (isOpen !== "add") {
      setSearchQuery("")
      reset();
    }
  }, [isOpen]);

  


  const handleClose =()=>{
    
    reset();
    setClose();
  }

  const handleMapClick = (clickedLat, clickedLng) => {
    setLat(clickedLat);
    setLng(clickedLng);
    setMarkerPosition({ lat: clickedLat, lng: clickedLng });
  };

  
  if (isOpen !== "add") return null;

  return ReactDOM.createPortal(
    <div className="uk-modal-dialog">
      <button
        className="uk-modal-close-default"
        type="button"
        uk-close="true"
        onClick={handleClose}
      ></button>
      <div className="uk-modal-header">
        <p className="uk-h4 uk-text-uppercase uk-text-secondary">
          Agregar Ubicación
        </p>
      </div>
      <div className="uk-modal-body">
     
          <div className="uk-grid uk-grid-small">
            <div className="uk-width-4-4 uk-margin-remove">
              <InputMask
                className="uk-input"
                name="search"
                maskChar={null}
                placeholder="Buscar...."
                onFocus={() => setFocus("search")}
                autoComplete={false}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <p className="error">
                {errors?.cardnumber?.message}
              </p>
            </div>
         </div>

      <div>
      <div
       className="uk-position-relative"
       uk-height-viewport="offset-bottom: 70"
     >
       <div id="gmapModal" className="gmap">
       <div id="store_map" className="uk-flex uk-flex-wrap">
       
          <div id="gmap" className="gmap">
          <GoogleMaps 
          stores={[]}
          search={searchQuery}
          onMapClick={handleMapClick}
          markerPosition={markerPosition}
          setMarkerPosition={setMarkerPosition}/>
          </div>
    
      </div>
       </div>
     </div>
      </div>
      
      </div>

      <div className="uk-modal-footer uk-text-center">
       
        <button
          onClick={setClose}
          className="uk-button uk-button-default uk-button-custom"
          type="button"
          disabled={loading}
        >
          <span>Aplicar</span> <i className="fas fa-check"></i>
        </button>
      </div>
    </div>,
    document.getElementById("webModal")
  );
}
