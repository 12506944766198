import React, { useContext, useEffect } from "react";
import AuthAnonymous from "components/account/auth/anonymous";
import AccountContext from "context/Account";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const { isLogin } = useContext(AccountContext);
  let navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      navigate("/", { replace: true });
    }
  }, [isLogin, navigate]);

  return <AuthAnonymous source="full" />;
}
