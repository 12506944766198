import axios from "axios";
import {
  ConsoleLog,
  Encode,
  GetAccount,
  GetValue,
  Logout,
  gtmPushEvent
} from "helpers/functions";

import alert from "helpers/alerts";
import UIkit from "uikit";
import { v4 as uuidv4 } from "uuid";
import { AuthRoute } from "shared/routes/auth-route";
//import * as Sentry from "@sentry/react";

const KEY_MAPS = process.env.REACT_APP_KEY_MAPS;

const ERROR_MSJ =
  "Ha ocurrido un error, por favor inténtelo de nuevo más tarde";

const DEFAULT_LAT = 18.47298056;
const DEFAULT_LON = -69.94255;

const SOURCE = btoa("storefront");

const URL_SERVICE = process.env.REACT_APP_BASE_API_URL;

const navigateToLogInPage = () => {
  window.location.replace(AuthRoute.SIGN_IN);
};

const performActionsWhenAuthIsRequired = (res, showModal, callback) => {
  Logout();
  if(showModal) {
    alert.confirm(res.data?.message ?? "Su sesión ha expirado, por favor inicie sesión de nuevo.", "error").then(() => {
      navigateToLogInPage();
      callback(null, true);
    });
    return;
  }

  navigateToLogInPage();
  callback(null, true);
};

const getData = (route, headers, callback, showModal = true) => {
  //var user = GetAccount();

  let visitorId = GetValue("visitorId");
  if (visitorId == null) {
    visitorId = uuidv4();
  }

  let fingerprint = Encode(visitorId);

  let url = `${URL_SERVICE}/${route}/${fingerprint}`;

  axios
    .get(url, {
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json",
        source: SOURCE,
        client: Encode(GetAccount("auth")),
      },
     // timeout:10000
    })
    .then((res) => {
      if (res.data?.auth_required) {
        performActionsWhenAuthIsRequired(res, showModal, callback);
        return;
      }

      if (res.data.error) {
        if (showModal) {
          alert.confirm(res.data.message, "error").then((result) => {
            callback(res.data, true);
           
          });
        } else {
          callback(res.data, true);
        }

        return;
      }

      callback(res.data);
    })
    .catch((err) => {
      //Sentry.captureMessage(`serverError: ${err}, url: ${url}`);
      gtmPushEvent(err)
      ConsoleLog(err);
      if (showModal) {
        alert.confirm(ERROR_MSJ, "error").then((result) => {
          callback(null, true);
        });
      } else {
        callback(null, true);
      }

      return;
    });
};

const postData = (route, data, headers, callback, showModal = true) => {
  let visitorId = GetValue("visitorId");
  if (visitorId == null) {
    visitorId = uuidv4();
  }

  let fingerprint = Encode(visitorId);

  let url = `${URL_SERVICE}/${route}/${fingerprint}`;

  axios
    .post(url, data, {
      headers: {
        ...headers,
        "Content-Type": "application/json",
        accept: "application/json",
        source: SOURCE,
        client: Encode(GetAccount("auth")),
      },
    })
    .then((res) => {
      if (res.data?.auth_required) {
        performActionsWhenAuthIsRequired(res, showModal, callback);
        return;
      }

      if (res.data.error) {
        if (showModal) {
          alert.confirm(res.data.message, "error").then(() => {
            callback(res.data, true);
            
          });
        } else {
          callback(res.data, true);
        }

        return;
      }

      callback(res.data);
    })
    .catch((err) => {
      //Sentry.captureMessage(`serverError: ${err}, url: ${url}, data: ${data}`);
      gtmPushEvent(err);
      ConsoleLog(err);
      if (showModal) {
        alert.confirm(ERROR_MSJ, "error").then(() => {
          callback(null, true);
        });
      } else {
        callback(null, true);
      }

      return;
    });
};

const getBlob = (route, headers, callback, showModal = true) => {
  //var user = GetAccount();
  let visitorId = GetValue("visitorId");
  if (visitorId == null) {
    visitorId = uuidv4();
  }

  let fingerprint = Encode(visitorId);

  let url = `${URL_SERVICE}/${route}/${fingerprint}`;

  //'Authorization': user["token"],
  axios({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
      source: SOURCE,
      client: Encode(GetAccount("auth")),
    },
  })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      //Sentry.captureMessage(`serverError: ${err}, url: ${url}`);
      gtmPushEvent(err);
      ConsoleLog(err);
      if (showModal) {
        alert.confirm(ERROR_MSJ, "error").then(() => {
          callback(null, true);
        });
      } else {
        callback(null, true);
      }
    });
};


export {
  getData,
  postData,
  getBlob,
  KEY_MAPS,
  DEFAULT_LAT,
  DEFAULT_LON,
};
