
import React, { useEffect, useContext,useState, useRef} from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Encode } from "helpers/functions";
import { getData, postData } from "helpers/service";
import Loading from "components/share/loading";
import MapsdAdd from "components/account/register/maps";
import UIkit from "uikit";
import AccountContext from "context/Account";
import alert from "helpers/alerts";
export default function RegisterAccountStep4({ form, onRegistered, previousStep }) {

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm();
  
  const [countryid] = useState(""); //dbe31cd5d3c64a4b0dfad6a1c344c0b3
  const [stateid, setStateid] = useState(null);
  const [state, setState] = useState([]);
  const [sector, setSector] = useState([]);
  const [sectorCurrent, setSectorCurrent] = useState({});
  const [sectorLoad, setSectorLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState("none");
  const [current, setCurrent] = useState({});
  const {addressMap, setAddProvince,setAddressMap} = useContext(AccountContext);
  const inputRef = useRef(null);

  useEffect(() => {
    getData(
      `setting/address/state`,
      {
        countryid: Encode(countryid),
        search: Encode(""),
      },
      (res, err) => {
        if (!err) {
          setState(res.data);
        }
      }
    );

  }, []);

  useEffect(() => {
    setSectorLoad(true);
    setSectorCurrent({});
    getData(
      `setting/address/sector`,
      {
        stateid: Encode(stateid),
        search: Encode(""),
      },
      (res, err) => {
        if (!err) {
          setSector(res.data);
        }
        setSectorLoad(false);
      }
    );
  }, [stateid]);

  const handleSelectChangeState = (option) => {

    setAddProvince(option.label);
    setAddressMap("")
    setStateid(option?.value);
    setValue("state_id", option?.value);
    setSector([]);
  };

  const handleSelectChangeSector = (option) => {
    setValue("sector_id", option?.value);
    setSectorCurrent(option);
  };

  const onOpenMap = (action = "add", data = []) => {
   
    setOpen(action);
    setCurrent(data);
    UIkit.modal("#webModal").show();
  }

  const onSetClose = (reload = false, message = "", data = null) => {

    if(addressMap === "") alert.confirm("Por favor, ingresa una dirección válida", "error").then(() => {});
    UIkit.modal(`#webModal`)?.hide();
    setOpen("none");

  };

 
  const onSubmit = (data) => {
    data.address = addressMap
    let record = { ...form, ...data };
   

    setLoading(true);
         
  postData("account", record, {}, (res, err) => {
    if (!err) {
      onRegistered();
    }

    setLoading(false);
  });
  };

  const ToPreviousStep = () => {
    previousStep();
  };

  const mySubmitHandler = (event) => {
    event.preventDefault();
  };

  return (
    <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
      <form className="uk-form-horizontal" onSubmit={mySubmitHandler}>
        <progress
          className="uk-progress uk-position-top"
          value="100"
          max="100"
        ></progress>
        <p className="uk-text-right uk-text-small uk-margin-remove-top">
          Paso 4 de 4
        </p>
        <div className="title-border">
          <p className="uk-h4">Zona residencial:</p>
        </div>
        <div className="uk-margin-small">
          <label className="uk-form-label" htmlFor="state_id">
            Alias <span className="required">*</span>
          </label>
          <div className="uk-form-controls">
           <input
                    className="uk-input"
                    id="tag"
                    name="tag"
                    {...register("tag", {
                      required: "Alias(s) son requeridos",
                    })}
                    type="text"
                    placeholder="Cómo desea guardar la dirección"
                  />
                  <p className="error">{errors?.tag?.message}</p>
          </div>
        </div>


        
        <div className="uk-margin-small">
          <label className="uk-form-label" htmlFor="state_id">
            Provincia <span className="required">*</span>
          </label>
          <div className="uk-form-controls">
            <Select
              placeholder="Seleccione..."
              options={state}
              onChange={handleSelectChangeState}
            />
            <input
              type="hidden"
              id="state_id"
              name="state_id"
              {...register("state_id", {
                required: "La provincia es requerida",
              })}
            />
            <p className="error">
              {errors.state_id && errors.state_id.message}
            </p>
          </div>
        </div>


        <div className="uk-margin-small">
          <label className="uk-form-label" htmlFor="sector_id">
            Sector <span className="required">*</span>
          </label>
          <div className="uk-form-controls">
            <Select
              placeholder="Seleccione..."
              isSearchable={true}
              value={sectorCurrent}
              isLoading={sectorLoad}
              options={sector}
              onChange={handleSelectChangeSector}
            />
            <input
              type="hidden"
              id="sector_id"
              name="sector_id"
              {...register("sector_id", { required: "El sector es Requerido" })}
            />
            <p className="error">
              {errors.sector_id && errors.sector_id.message}
            </p>
          </div>
        </div>


        <div className="uk-width-1-1 uk-margin-remove">
                  <label
                    className="uk-form-label uk-form-label-full"
                    htmlFor="address"
                  >
                    Dirección (Calle/Número)<span className="required">*</span>
                  </label>
                  <textarea
                    id="address"
                    name="address"
                    cols="30"
                    rows="2"
                    value={addressMap}
                    onClick={() => onOpenMap()}
                    ref={inputRef}
                    uk-toggle="target: #webModal"
                    className="uk-textarea"
                    {...register("address", {
                      required: addressMap?"": "la dirección (Calle/Número) es requerida",
                    })}
                  ></textarea>
                  <p className="error">{errors?.address?.message}</p>
                </div>

                <div className="uk-width-1-1 uk-margin-remove">
                  <label
                    className="uk-form-label uk-form-label-full"
                    htmlFor="address2"
                  >
                    Dirección (Residencial / Casa o Apto. No.){" "}
                    <span className="required">*</span>
                  </label>
                  <textarea
                    id="address2"
                    name="address2"
                    cols="30"
                    rows="2"
                    className="uk-textarea"
                    {...register("address2")}
                  ></textarea>
                  <p className="error">{errors?.address2?.message}</p>
                </div>

        <div className="uk-margin">
          <label>
            <input
              id="checkboxTerms"
              name="checkboxTerms"
              {...register("checkboxTerms", {
                required:
                  "Debe aceptar Términos y condiciones para registrarte.",
              })}
              className="uk-checkbox"
              type="checkbox"
            />{" "}
            Cuando te registras, aceptas los{" "}
            <a
              href="/site/page/terminos-y-condiciones"
              target="_blank"
              rel="noopener noreferrer"
            >
              Términos y Condiciones
            </a>{" "}
            de Sirena.do
          </label>
          <p className="error">
            {errors?.checkboxTerms && errors?.checkboxTerms.message}
          </p>
        </div>
        {loading && <Loading />}
        <hr />
        <div className="uk-margin uk-text-right">
          <button
            type="button"
            className="uk-button  uk-button-custom"
            disabled={loading}
            onClick={ToPreviousStep}
          >
            Anterior
          </button>
          <button
            id="btn-sign-up"
            type="button"
            className="uk-button uk-button-primary"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Registrarme
          </button>
        </div>
      </form>
      <MapsdAdd isOpen={open} setClose={onSetClose} />
    </div>
  );
}
