
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import StepWizard from "react-step-wizard";
import RegisterAccountStep1 from "components/account/register/step-1";
import RegisterAccountStep2 from "components/account/register/step-2";
import RegisterAccountStep3 from "components/account/register/step-3";
import RegisterAccountStep4 from "components/account/register/step-4";
import AccountContext from "context/Account";

import { getData } from "helpers/service";
import { setTitle } from "helpers/functions";
import { BannerStepUser5Model } from "models/adsense";
import Advertisements from "components/share/adsense";
import { GetTypeDevice } from "helpers/functions";

export default function RegisterPage() {

  const { isLogin } = useContext(AccountContext);
  const [isRegistered, setIsRegistered] = useState(false);
  const [form, setForm] = useState({});
  const [gender, setGender] = useState([]);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [invoiceTypeOptions, setInvoiceTypeOptions] = useState([]);

  const navigate = useNavigate();
  const currentType = GetTypeDevice();
 
  setTitle("Registro de usuario");

  const handleDataChange = (data) => {
    setForm((prevState)=>({...prevState, ...data}));
  };

  const onRegistered = () => {
    setIsRegistered(true);
  };

  useEffect(() => {
    getData(`setting/account`, null, (res, err) => {
      if (!err) {
        setGender(res.gender);
        setDocumentTypeOptions(res.document_type);
        setInvoiceTypeOptions(res.invoice_type);
      }
    });
  }, []);

  useEffect(() => {
    if (isLogin) {
      navigate("/", { replace: true });
    }
  }, [isLogin, navigate]);

  const RegisterResult = () => (
    <>
      <div className="uk-section uk-margin-medium-bottom">
        <div className="uk-container uk-flex uk-flex-center">
          <div className="uk-width-1-1 uk-width-2-3@m uk-width-3-5@l">
            <div
              className={`uk-section uk-section-small uk-flex uk-flex-center ${
                currentType === "desktop" ? "uk-padding-remove-bottom" : ""
              }`}
            >
              {BannerStepUser5Model.filter(
                (item) => item.type === currentType
              ).map((item) => (
                <Advertisements
                  key={item.slotId}
                  className={item.className}
                  type={item.type}
                  slotId={item.slotId}
                  sizes={item.sizes}
                  adUnit={item.adUnit}
                  targetingArguments={{
                    Seccion: "Formulario de registro paso 5",
                  }}
                />
              ))}
            </div>
            <h2 className="uk-h2 uk-text-center">¡Gracias por registrarte!</h2>
            <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-box-shadow-small">
              <div className="title-border uk-text-center">
                <p className="uk-h4">Activa tu cuenta</p>
              </div>
              <p className="uk-text-center">
                Antes de poder iniciar sesión necesitamos que actives tu cuenta
                haciendo click en el enlace de activación en el correo
                electrónico que acabamos de enviarte a{" "}
                <strong>{form?.account}</strong> y estarás listo para disfrutar
                de <strong>Sirena.do</strong>
              </p>
              <p className="uk-text-center uk-text-secondary">
                <i className="fas fa-exclamation-triangle fa-3x"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="uk-section uk-margin-medium-bottom">
      <div className="uk-container uk-flex uk-flex-center">
        {isRegistered ? (
          <RegisterResult />
        ) : (
          <div className="uk-width-1-1 uk-width-2-3@m">
            <h2 className="uk-h2 uk-text-center">Registro de usuario</h2>
            <p className="uk-text-small uk-text-center">
              (*) Los campos marcados con asteriscos son obigatorios.
            </p>
            <StepWizard>
              <RegisterAccountStep1 handleDataChange={handleDataChange} />
              <RegisterAccountStep2
                gender={gender}
                handleDataChange={handleDataChange}
              />
            <RegisterAccountStep3
                documentTypeOptions={documentTypeOptions}
                invoiceTypeOptions={invoiceTypeOptions}
                handleDataChange={handleDataChange}
              />
              
              <RegisterAccountStep4 form={form} onRegistered={onRegistered} />
            </StepWizard>
          </div>
        )}
      </div>
    </div>
  );
}
