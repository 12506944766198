/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useReducer } from "react";
import { getData, postData } from "helpers/service";
import Loading from "components/share/loading/table";
import { DecimalFormat, Encode, setTitle } from "helpers/functions";
import { useNavigate, useParams } from "react-router-dom";
import UIkit from "uikit";
import LoadingModal from "components/share/loading/modal";
import TimeSlot from "components/cart/checkout/timeslot";
import ContactsList from "components/cart/checkout/contacts";
import InputSearch from "../../components/share/order/InputSearch";
import OrderItem from "../../components/share/order/OrderItem";
import PageNotFound from "components/share/error/404";
import CommentItem from "components/share/commentItem";
import {
  OPEN,
  ORDER,
  BALANCE,
  PICKUP_ID,
  LOCATION_SLOT_ID,
  LOCATION_SLOT,
  CURRENT_BALANCE,
  CURRENT_TOTAL,
  BASE_IMG,
  ITEMS,
  LOADING,
  CURRENT_ITEM,
  CURRENT_IDX,
  TMP_KEY,
} from "store/account/orderModify/actionTypes";
import { updateStateReducer } from "store/common/commonReducer";
import { initialState } from "store/account/orderModify/state";

const OrderModifyPage = () => {
  let { orderkey } = useParams();
  let navigate = useNavigate();

  const [
    {
      open,
      order,
      balance,
      pickupId,
      locationslotId,
      locationslot,
      currentBalance,
      currentTotal,
      baseImg,
      items,
      loading,
      currentItem,
      currentIdx,
      tmpkey,
    },
    dispatch,
  ] = useReducer(updateStateReducer, initialState);

  setTitle("Modificar mi Pedido");

  useEffect(() => {
    getData(`account/order/${orderkey}/modify`, {}, (res, err) => {
      if (!err) {
        dispatch({ type: ORDER, payload: res.order });
        dispatch({ type: BALANCE, payload: res.balance });
        dispatch({ type: ITEMS, payload: res.items });
        dispatch({ type: BASE_IMG, payload: res.base_img });
        dispatch({ type: TMP_KEY, payload: res.tmpkey });
        dispatch({ type: PICKUP_ID, payload: res.order.pickupid });
        dispatch({ type: LOCATION_SLOT_ID, payload: res.order.locationslotid });
        dispatch({ type: CURRENT_BALANCE, payload: 0 });
        dispatch({ type: CURRENT_TOTAL, payload: res?.balance?.total_amount });
      }
      dispatch({ type: LOADING, payload: false });
    });
  }, [orderkey]);

  const onChangeNotReplace = (idx, item, e) => {
    let value = e.target.checked;
    let data = {
      action: "update",
      itemid: item.key_id,
      productid: item.product_key_id,
      not_replace: value ? 1 : 0,
      quantity: item.quantity,
      comment: item.comment,
    };
    //setNotReplace(!notReplace);
    SubmitModify(idx, data);
  };

  const handleAddItem = (res) => {
    dispatch({ type: ITEMS, payload: [res.item, ...items] });
    dispatch({
      type: CURRENT_BALANCE,
      payload:
        parseFloat(res.balance?.total) - parseFloat(balance?.order_amount),
    });
    dispatch({ type: CURRENT_TOTAL, payload: res.balance?.total });
  };

  const onSetClose = () => {
    UIkit.modal(`#webModal`)?.hide();
    dispatch({ type: OPEN, payload: "none" });
    dispatch({ type: CURRENT_IDX, payload: -1 });
    dispatch({ type: CURRENT_ITEM, payload: {} });
  };

  const RemoveItem = (idx, Item) => {
    let data = {
      action: "delete",
      itemid: Item.key_id,
      productid: Item.product_key_id,
    };
    SubmitModify(idx, data, false);
  };

  const restoreItem = (idx, Item, setIsRemoved) => {
    let data = {
      action: "restore",
      itemid: Item.key_id,
      productid: Item.product_key_id,
    };
    SubmitModify(idx, data, false);
    setIsRemoved(false);
  };

  const onSetComment = (comment = "") => {
    let data = {
      action: "update",
      itemid: items[currentIdx].key_id,
      productid: items[currentIdx].product_key_id,
      not_replace: items[currentIdx].not_replace,
      quantity: items[currentIdx].quantity,
      comment: comment,
    };

    SubmitModify(currentIdx, data);
  };

  const opencomment = (idx) => {
    dispatch({ type: CURRENT_ITEM, payload: items[idx] });
    dispatch({ type: CURRENT_IDX, payload: idx });
    dispatch({ type: OPEN, payload: "comment" });

    UIkit.modal(`#webModal`)?.show();
  };

  const SubmitModify = (idx, data, allow = true) => {
    postData(
      `account/order/${orderkey}/modify/item`,
      data,
      { tmpkey: tmpkey },
      (res, err) => {
        if (!err) {
          if (allow) {
            let copyItems = items;

            copyItems[idx].comment = data.comment;
            copyItems[idx].not_replace = data.not_replace;
            copyItems[idx].quantity = data.quantity;

            dispatch({ type: ITEMS, payload: copyItems });
          }
          dispatch({
            type: CURRENT_BALANCE,
            payload:
              parseFloat(res.balance?.total) -
              parseFloat(balance?.order_amount),
          });
          dispatch({ type: CURRENT_TOTAL, payload: res.balance?.total || 0 });
          onSetClose();
        }
      }
    );
  };

  const onSubmit = () => {
    dispatch({ type: OPEN, payload: "loading" });
    UIkit.modal(`#webModal`).show();
    postData(
      `account/order/${orderkey}/modify`,
      {
        tmpkey: Encode(tmpkey),
        key: Encode(orderkey),
        pickupid: Encode(pickupId),
        locationslotid: Encode(locationslotId),
      },
      null,
      (res, err) => {
        if (!err) {
          navigate(`/order-confirmation/${Encode(orderkey)}`, {
            replace: true,
          });
        } else {
          dispatch({ type: LOADING, payload: "false" });
          window.location.reload();
        }
        dispatch({ type: OPEN, payload: "none" });
        UIkit.modal(`#webModal`)?.hide();
      }
    );
  };

  const onSetTimeSlot = (xlocationslotId, xlocationslot) => {
    dispatch({ type: OPEN, payload: "none" });
    UIkit.modal(`#webModal`)?.hide();
    dispatch({ type: LOCATION_SLOT_ID, payload: xlocationslotId });
    dispatch({ type: LOCATION_SLOT, payload: xlocationslot });
  };

  const onSetContact = (reload = false, message = "", data = null) => {
    dispatch({ type: OPEN, payload: "none" });
    if (data !== null) {
      let newDataOrder = order;

      newDataOrder.pickup_first_name = data.first_name;
      newDataOrder.pickup_last_name = data.last_name;
      newDataOrder.pickup_document = data.document_type_id;
      newDataOrder.pickup_documentType = data.document;

      dispatch({ type: PICKUP_ID, payload: data.id });
      dispatch({ type: ORDER, payload: newDataOrder });
    }
    UIkit.modal(`#webModal`)?.hide();
  };

  const alertPrice = () =>{

   return alert.confirm(
     `El monto de esta orden está por debajo del mínimo requerido.`,
     "warning"
   );;
 
  }

  if (order.allow_modify === 0)
    return (
      <PageNotFound
        message={`Esta orden ya no puede ser modificada. Pedido No.: ${order?.codigo}`}
      />
    );
  return (
    <div
      className="uk-section uk-margin-medium-bottom"
      style={{ minHeight: 800 }}
    >
      <div className="uk-container">
        <div className="title-border uk-margin-medium-bottom">
          <h2 className="uk-h3">MODIFICAR MI PEDIDO</h2>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <div className="uk-grid uk-grid-large item-list-wrap" uk-grid="true">
            <div className="uk-width-1-1 uk-width-2-3@m">
              <div className="uk-card uk-card-default uk-border-rounded uk-box-shadow-small">
                <div
                  className="uk-flex uk-flex-between uk-flex-middle uk-grid-small uk-text-small uk-margin-medium-bottom nav-filters"
                  uk-grid="true"
                >
                  <div>
                    <span className="uk-inline">
                      <i className="fas fa-cart-plus"></i> {items?.length}{" "}
                      {items?.length > 1 ? "artículos" : "artículo"}
                    </span>
                  </div>
                </div>
                <InputSearch
                  order={order}
                  orderkey={orderkey}
                  tmpkey={tmpkey}
                  handleAddItem={handleAddItem}
                  onSetClose={onSetClose}
                />
                <hr />
                <ul className="uk-list uk-list-large">
                  {items.map((item, idx) => (
                    <OrderItem
                      key={item?.product_key_id}
                      idx={idx}
                      item={item}
                      SubmitModify={SubmitModify}
                      baseImg={baseImg}
                      onChangeNotReplace={onChangeNotReplace}
                      opencomment={opencomment}
                      RemoveItem={RemoveItem}
                      restoreItem={restoreItem}
                    />
                  ))}
                </ul>
              </div>
            </div>

            <div className="uk-width-1-1 uk-width-1-3@m">
              <div
                className="item-list-footer"
                uk-sticky="offset: 130; bottom: true; media: @m"
              >
                <div className="item-list-total">
                  <div className="title-border">
                    <p className="uk-h4">Pedido {order.codigo}</p>
                  </div>

                  <div>
                    <div className="uk-flex uk-grid-small">
                      <div className="uk-width-1-3 uk-text-uppercase"></div>
                      <div className="uk-width-2-3 uk-text-right">
                        {order.order_date}
                      </div>
                    </div>
                  </div>

                  <p>
                    <strong>{order.shippingrate}</strong>
                  </p>

                  {order.shippingratetype === "P" && (
                    <>
                      <p className="uk-flex uk-grid-small">
                        <i className="fas fa-user"></i>
                        <span>
                          <strong>
                            {order.pickup_first_name} {order.pickup_last_name}
                          </strong>{" "}
                          (
                          <a
                            href="#"
                            onClick={(e) => {
                              dispatch({ type: OPEN, payload: "contacts" });
                              e.preventDefault();
                            }}
                            className="uk-button uk-button-link uk-padding-remove"
                            uk-toggle="target: #webModal"
                          >
                            {" "}
                            {"Cambiar"}
                          </a>
                          )<br />
                          {order.pickup_documentType}: {order.pickup_document}
                        </span>
                      </p>
                      <p className="uk-flex uk-grid-small">
                        <i className="fas fa-map-pin"></i>
                        <span>
                          <strong>{order.location}</strong>
                          <br /> {order.location_address}
                        </span>
                      </p>
                    </>
                  )}

                  {order.shippingratetype === "D" && (
                    <>
                      <p className="uk-flex uk-grid-small">
                        <i className="fas fa-map-pin"></i>
                        <span>{`${order.shipping_address} ${order.shipping_address2} ${order.shipping_sector} ${order.shipping_state}`}</span>
                      </p>
                    </>
                  )}
                  {order.req_timeslot === 1 ? (
                    <div className="uk-text-small uk-alert uk-alert-warning">
                      <i className="fas fa-exclamation-triangle"></i>
                      <p>
                        Este horario ya no esta disponible para entrega,
                        Seleccione nuevamente.
                      </p>
                    </div>
                  ) : null}
                  <p className="uk-flex uk-grid-small">
                    <i className="far fa-calendar-alt"></i>
                    <span>
                      <strong>{order.timeslot}</strong> (
                      <a
                        href="#"
                        onClick={(e) => {
                          dispatch({ type: OPEN, payload: "timeslot" });
                          e.preventDefault();
                        }}
                        className="uk-button uk-button-link uk-padding-remove"
                        uk-toggle="target: #webModal"
                      >
                        {" "}
                        {"Cambiar"}
                      </a>
                      )
                      {locationslot !== "" && (
                        <>
                          <br />{" "}
                          <strong className="uk-text-danger">
                            {locationslot}
                          </strong>
                        </>
                      )}
                    </span>
                  </p>
                </div>

                <hr className="uk-margin-medium-top" />

                <div className="item-list-total">
                  <div>
                    <div className="uk-flex uk-grid-small">
                      <div className="uk-width-1-3 uk-text-uppercase">
                        Pagado:
                      </div>
                      <div className="uk-width-2-3 uk-text-right">
                        RD$ {DecimalFormat(balance?.order_amount || 0)}
                      </div>
                    </div>
                    <div className="uk-flex uk-grid-small uk-text-bolder">
                      <div className="uk-width-1-3 uk-text-uppercase">
                        Total:
                      </div>
                      <div className="uk-width-2-3 uk-text-right">
                        RD$ {DecimalFormat(currentTotal)}
                      </div>
                    </div>
                    <div className="uk-flex uk-grid-small uk-text-bolder uk-text-secondary">
                      <div className="uk-width-1-3 uk-text-uppercase">
                        Por Pagar:
                      </div>
                      <div className="uk-width-2-3 uk-text-right">
                        RD$ {DecimalFormat(currentBalance)}
                      </div>
                    </div>
                  </div>

                  <div
                    className="uk-flex uk-flex-wrap uk-flex-center uk-grid-small item-list-btns"
                    uk-grid="true"
                  >
                    <div>
                      <button
                        onClick={onSubmit}
                        className="uk-button uk-button-primary"
                      >
                        <span>Proceder</span>
                        <i className="fas fa-chevron-right"></i>
                      </button>
                      <div className="uk-width-1-1 uk-padding-small">
                 
                  </div>
                  
                    </div>
                    <div className="uk-text-small uk-alert uk-alert-primary">
                      <i className="fas fa-exclamation-triangle"></i>
                       <p>Solo podrá modificar su orden hasta el 15% del monto original</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <CommentItem
          isOpen={open}
          item={currentItem}
          handleSetComment={onSetComment}
          onSetClose={onSetClose}
        />

        <LoadingModal
          isOpen={open}
          message="Estamos procesando los cambios de tu pedido, por favor espera..."
        />

        <TimeSlot
          isOpen={open}
          onSetClose={onSetClose}
          onSetTimeSlot={onSetTimeSlot}
        />
        <ContactsList
          isOpen={open}
          onSetClose={onSetClose}
          onSetContact={onSetContact}
        />
      </div>
    </div>
  );
};
export default OrderModifyPage;
