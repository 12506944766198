import React, { useEffect, useState, useContext } from "react";
import Loading from "components/share/loading/home";
import ProductCategory from "components/product/category";
import { getData } from "helpers/service";
import Banner from "components/home/banner";
import ProductItem from "components/product/item";
import { Helmet } from "react-helmet";
import HomeNotification from "components/home/notifications";
import { Link } from "react-router-dom";
import { GetTypeDevice } from "helpers/functions";
import Advertisements from "components/share/adsense";
import AccountContext from "context/Account";

export default function HomePage() {
  const [content, setContent] = useState("");
  const [banner, setBanner] = useState([]);
  const [specials, setSpecials] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [baseImg, setBaseImg] = useState("");
  const [baseImgProduct, setBaseImgProduct] = useState("");
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const { account, reloadItems } = useContext(AccountContext);
  const currentType = GetTypeDevice();
  const TEMP_VALUE_SPECIAL_CATEGORY = "especiales-del-dia";

  useEffect(() => {
    setLoading(true);
    getData(`setting/site/home`, null, (res, err) => {
      if (!err) {
        setContent(res.home.content);
        setBanner(res.banner);
        setBaseImg(res.base_img_banner);
        setBaseImgProduct(res.base_img_product);
        setSpecials(res.specials);
        setRecipes([]);
        setNotifications(res.notification);
      }
      setLoading(false);
    });
  }, [account]);

  useEffect(() => {
    if (reloadItems === false) return;
    setLoading(true);
    getData(`setting/site/home`, null, (res, err) => {
      if (!err) {
        setSpecials(res.specials);
      }
      setLoading(false);
    });
  }, [reloadItems]);

  const SpecialyView = () => {
    return (
      <section className="uk-section uk-section-small">
        <div className="uk-container uk-container-expand">
          <div className="title-border">
            <h2 className="uk-h4">Especiales del día</h2>
            <Link
              to={`/products/specials`}
              className="uk-button uk-button-text"
            >
              <span>ver todos</span> +
            </Link>
          </div>
          <div className="uk-background-tertiary uk-padding-small">
            <div className="slider-products"
                 data-id={TEMP_VALUE_SPECIAL_CATEGORY}
                 data-name={TEMP_VALUE_SPECIAL_CATEGORY}
                 data-parent-category-id={TEMP_VALUE_SPECIAL_CATEGORY}
                 data-departament={TEMP_VALUE_SPECIAL_CATEGORY}
              uk-slider="sets: true">
              <div className="uk-position-relative">
                <div className="uk-slider-container">
                  <ul className="uk-slider-items uk-grid-match">
                    {specials.map((p) => (
                      <li key={p.productid}>
                        <ProductItem
                          key={p.productid}
                          baseImg={baseImgProduct}
                          item={p}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <a
                  className="uk-position-center-left-out slider-arrow"
                  href="#0"
                  uk-slider-item="previous"
                >
                  <i className="fas fa-chevron-left"></i>
                </a>
                <a
                  className="uk-position-center-right-out slider-arrow"
                  href="#0"
                  uk-slider-item="next"
                >
                  <i className="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const RecipeView = () => {
    return (
      <section className="uk-section uk-section-small">
        <div className="uk-container uk-container-expand">
          <div className="title-border">
            <h2 className="uk-h4">Recetas de temporada</h2>
            <Link to={`/recipes`} className="uk-button uk-button-text">
              <span>ver todos</span> +
            </Link>
          </div>
          <div className="slider-recipes" uk-slider="sets: true">
            <div className="uk-position-relative">
              <div className="uk-slider-container"></div>
              <a
                className="uk-position-center-left-out slider-arrow"
                href="#0"
                uk-slider-item="previous"
              >
                <i className="fas fa-chevron-left"></i>
              </a>
              <a
                className="uk-position-center-right-out slider-arrow"
                href="#0"
                uk-slider-item="next"
              >
                <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const HomeView = () => {
    return (
      <>
        <Banner banners={banner} baseImg={baseImg} />
        <ProductCategory />
        {specials?.length > 0 && <SpecialyView />}
        {recipes?.length > 0 && <RecipeView />}

        <div
          className={`uk-section uk-section-small uk-padding-remove-top uk-flex uk-flex-center movil_home_standard banner_movil ${
            currentType !== "mobile" && "uk-hidden"
          }`}
        >
          <Advertisements
            style={{ minWidth: 350, minHeight: 90 }}
            type="mobile"
            sizes={[350, 90]}
            adUnit="Movil_Home_Standard"
            slotId="div-gpt-ad-1640176460245-0"
            targetingArguments={{ Seccion: "home" }}
          />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="uk-container"
        />
        <div
          className={`uk-section uk-section-small uk-padding-remove-top uk-flex uk-flex-center movil_home_standard banner_movil ${
            currentType !== "mobile" && "uk-hidden"
          }`}
        >
          <Advertisements
            style={{ minWidth: 350, minHeight: 90 }}
            type="mobile"
            sizes={[350, 90]}
            adUnit="Movil_Home_Standard_2"
            slotId="div-gpt-ad-1644420897178-0"
            targetingArguments={{ Seccion: "home" }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Sirena Go, es el nuevo servicio de compra online de La Sirena. Nuestro objetivo es hacerte la vida más simple, ofrecerte soluciones que te permitan disfrutar más, ahorrándote tiempo y adaptándonos a tu estilo de vida."
        />
        <meta
          name="keywords"
          content="sirena, gruporamos, supermarket, supermercado, online, santo domingo, dominicana, compras, compra, tienda, productos, carnes, frutas, cereales, delivery, conservas, reposteria, dulces, bebidas, liquor store, bebe, desechables, limpieza, ahorro, empresas"
        />
        <meta name="author" content="Grupo Ramos" />
        <meta name="robots" content="index, follow" />

        {/*<!-- Twitter Card data -->*/}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://sirena.do/" />
        <meta name="twitter:title" content="Sirena - Más de una emoción" />
        <meta
          name="twitter:description"
          content="Sirena Go, es el nuevo servicio de compra online de La Sirena. Nuestro objetivo es hacerte la vida más simple, ofrecerte soluciones que te permitan disfrutar más, ahorrándote tiempo y adaptándonos a tu estilo de vida."
        />

        {/*<!-- Twitter summary card  -->*/}
        <meta
          name="twitter:image:src"
          content="https://assets-sirenago.s3-us-west-1.amazonaws.com/site/imagen/logo-01.png"
        />

        {/*<!-- Open Graph+- data -->*/}
        <meta property="og:title" content="Sirena - Más de una emoción" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sirena.do/" />
        <meta
          property="og:image"
          content="https://assets-sirenago.s3-us-west-1.amazonaws.com/site/imagen/logo-01.png"
        />
        <meta
          property="og:description"
          content="Sirena Go, es el nuevo servicio de compra online de La Sirena. Nuestro objetivo es hacerte la vida más simple, ofrecerte soluciones que te permitan disfrutar más, ahorrándote tiempo y adaptándonos a tu estilo de vida."
        />
        <meta property="og:site_name" content="Sirena - Más de una emoción" />
      </Helmet>

      {loading ? <Loading /> : <HomeView />}
      <HomeNotification notifications={notifications} />
    </>
  );
}
