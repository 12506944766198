/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import SubNavCategory from "./category";
import AccountContext from "context/Account";
import ShippingRate from "components/cart/checkout/shipping-rate";
import UIkit from "uikit";
import AdsDeliveryExpress from "../adsDeliveryExpress";

export default function MenuBar() {
    const {isLogin, onLogIn, categories, account} = useContext(AccountContext);
    const [open, setOpen] = useState("none");
    const [isShippingRate, setIsShippingRate] = useState(true);
    const [shippingType, setShippingType] = useState(null);
    const location = useLocation();
    const SHIPPING_TYPE_ICON = {
        P: "fas fa-store",
        D: "fas fa-truck",
        DE: "fas fa-motorcycle",
    };

    useEffect(() => {
        if (
            location.pathname.startsWith("/ce/cart/") ||
            location.pathname.startsWith("/checkout")
        ) {
            setIsShippingRate(false);
        }
        return () => setIsShippingRate(true);
    }, [location]);

    // Delivery express
    /*
     useEffect(() => {
       if (location.pathname !== '/') return;
       let showAgain = GetSession("AdsDeliveryExpress");
       if (showAgain === "false") return;
       if (account?.default_shipping_type === DELIVERY_EXPRESS) return;
       if (!isLogin) return;
       setOpen("ads-delivery-express");
       UIkit.modal("#webModal-container")?.show();
     }, [isLogin]);
   */
    useEffect(() => {
        if (shippingType) setOpen("shipping-rate");
    }, [shippingType]);


    const onSetClose = (action, modal) => {
        setOpen(action);
        UIkit.modal(`#${modal}`)?.hide();
    };

    const onSetShippingRate = (data) => {
        onLogIn(data.session);
        setOpen("none");
        UIkit.modal(`#webModal-container`)?.hide();
    };

    return (
        <div className={"uk-container uk-container-expand top-sub uk-sticky" + (isLogin && ' top-sub-logged')}
            uk-sticky="show-on-up: false; animation: uk-animation-slide-top">
            <nav className="uk-navbar-container uk-navbar-transparent uk-navbar"
                uk-navbar="mode: click"
                id="blue-home-categorybar">
                <div className="uk-navbar-left uk-visible@m">
                    <ul className="uk-navbar-nav">
                        <li>
                            <a href="#0">
                                Productos <i className="fas fa-caret-down"></i>
                            </a>
                            <SubNavCategory vdetails={categories}/>
                        </li>
                        <li>
                            <Link to="/products/specials">Especiales</Link>
                        </li>
                        <li>
                            <Link to="/products/favorites">Mis favoritos</Link>
                        </li>
                        <li>
                            <Link to="/account/shopping-lists">Mis Listas</Link>
                        </li>
                        <li>
                            <Link to="/sirena-cash">
                                Sirena Cash
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="uk-navbar-right">
                {
                    isLogin && isShippingRate && (
                        <div id='blue-button-shipping' data-test="blue-button-shipping">
                            <a href="#0"
                                onClick={() => setOpen("shipping-rate")}
                                className="btn-client-store uk-navbar-item"
                                uk-toggle="target: #webModal-container">
                                    <i className={SHIPPING_TYPE_ICON[account?.default_shipping_type]}></i>
                                    <span>
                                        <small>{account?.default_shipping}</small>
                                        {account?.default_location}
                                    </span>
                            </a>
                        </div>
                    )
                }
                    <div className="logo logo-sub">
                        <a target="blank"
                            href="https://api.whatsapp.com/send?phone=18293224444&text=Deseo%20una%20ayuda%20con"
                            className="btn-whatsapp uk-navbar-item">
                            <i className="fab fa-whatsapp"></i>
                        </a>
                    </div>
                </div>
            </nav>
            <ShippingRate
                isOpen={open}
                onSetClose={onSetClose}
                onSetShippingRate={onSetShippingRate}
                type={shippingType}
            />
            <AdsDeliveryExpress
                isOpen={open}
                onSetClose={onSetClose}
                setShippingType={setShippingType}
            />
        </div>
    );
}
